<template>
  <div class="bankManagement-list">
    <app-list :opt="opt" @get="onGet" ref="myList"></app-list>

    <div class="bankManagementDetail">
      <!-- 新增支行信息弹窗 -->
      <el-dialog title="新增支行信息" :visible.sync="dialogFormVisible" :close-on-click-modal=false width='750px' @close="formClose()">
          <div class="info-tips">说明：1. 银行支行名称和支行行号需要自行在网上搜索，例如: 百度搜 工商银行宜春富民支行行号；</div>
          <div class="info-tips" style="margin:0px 0px 20px 42px">2. 新增的银行支行名称和支行行号不能有误，否则无法绑定银行信息。</div>
          <el-form :model="addForm"  :rules="addRules" ref="addForm">
              <el-form-item label-width="170px" label="开户行名称" prop="openBankName">
                  <el-select style="width: 420px; display: inline-block;"  filterable remote reserve-keyword :remote-method="remoteOpenMethod"
                      v-model="addForm.openBankName" ref="select" placeholder="根据开户行名称搜索" clearable>
                      <el-option v-for="it in openBankList" :key="it.label" :label="it.label" :value="it.label"></el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label-width="170px" label="支行行名" prop="bankName">
                  <el-input style="width: 420px;display: inline-block;" v-model.trim="addForm.bankName" placeholder="请输入支行行名" clearable></el-input>
              </el-form-item>
              <el-form-item label-width="170px" label="支行行号" prop="payBankNo">
                  <el-input style="width: 420px;display: inline-block;" v-model.trim="addForm.payBankNo" placeholder="请输入支行行号" clearable></el-input>
              </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
              <el-button @click="formClose()">取 消</el-button>
              <el-button type="primary" @click="infoSubmit()" >确 定</el-button>
          </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
  let validBankName = (rule, value, callback) => {
        let reg = /^[\u4E00-\u9FA5]{0,64}$/;
        if (!value) {
            callback(new Error("请输入支行行名"));
        }else if (value.length > 64) {
            callback(new Error("支行行名不能超过64位"));
        }else if (!reg.test(value)) {
            callback(new Error("只能输入中文字符"));
        } else {
            callback();
        }
    };
    let validPayBankNo = (rule, value, callback) => {
        let reg = /^[0-9]*$/;
        if (!value) {
            callback(new Error("请输入支行行号"));
        }else if (value.length > 64) {
            callback(new Error("支行行号不能超过64位"));
        }else if (!reg.test(value)) {
            callback(new Error("只能输入数字"));
        } else {
            callback();
        }
    };
  export default {
    data() {
      let _this = this;
      return {
        pageCurrent: 1,
        pageSize: 50,
        pageNum: 1,
        loading: false,
        dialogFormVisible: false,
        openBankList: [],
        addForm: {
            openBankName: '',
            bankName: '',
            payBankNo: '',
        },
        addRules: {
            openBankName: [{
                required: true,
                message: "请输入开户行名称",
                trigger: ['change', 'blur']
            }],
            bankName: [{
                required: true,
                validator: validBankName,
                trigger: ['change', 'blur']
            }],
            payBankNo: [{
                required: true,
                validator: validPayBankNo,
                trigger: ['change', 'blur']
            }],
        },
        opt: {
          title: "银行信息管理",
          search: [{
              key: "openBankName",
              label: "开户行名称",
              type: 'selectOpenBank',
              opt: {
                list: [],
                remoteMethod(value) {
                  _this.remoteOpenMethod(value)
                } 
              }
            }, {
              key: "bankName",
              label: "支行名称",
            },
            {
              key: "payBankNo",
              label: "支行行号",
            }
          ],
          columns: [{
              label: "开户行名称",
              key: "openBankName",
              width: 400
            },
            {
              label: "支行名称",
              key: "bankName"
            },
            {
              label: "支行行号",
              key: "payBankNo"
            },
          ],
          buttons: [{
              type: 0,
              on() {
                _this.addBankInfo()
              }
            },
          ]
        },
      };
    },
    created() {
      console.log("bankManagement-list created!!");
    },
    activated() {
      console.log("bankManagement-list activated!!");
      this.pageSize = 10;
      this.openQuery = ''
      this.openBankList = [];
    },
    deactivated() {
    },

    methods: {
      onGet(opt) {
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          ...opt.searchForm
        };
        this.post("mall-service/merchant/v1/bank/page", dto, {
          isUseResponse: true
        }).then(res => {
          let obj = {
            data: [],
            total: 0
          }
          obj.data = res.data.data.data;
          obj.total = res.data.data.total
          opt.cb(obj);
        });
      },
      addBankInfo() {
        this.dialogFormVisible = true
        this.openQuery = ''
        this.openBankList = [];
      },
      getOpenBankList() {
          if(!this.openQuery) {
              return
          }
          let dto = {
              pageSize: this.pageSize,
              pageCurrent: this.pageCurrent,
              bankName: this.openQuery,
          };
          this.loading = true;
          let url = 'mall-service/merchant/v1/open/bank/list';
          this.post(url, dto).then(res => {
              this.loading = false;
              if (this.pageSize == res.total) {
                  this.noMore = true
              } else {
                  this.noMore = false;
              }
              this.pageSize = this.pageSize + 10;
              res = JSON.parse(JSON.stringify(res).replace(/bankName/g, "label"));
              this.openBankList = [];
              this.openBankList = res;
              this.opt.search.forEach((item,index) => {
                if (item.key == 'openBankName') {
                  item.opt.list = this.openBankList
                }
              })
          }).catch(err => {
              this.loading = false;
          })
      },
      remoteOpenMethod(value) {
          this.pageSize = 50;
          this.pageCurrent = 1;
          this.loading = false;
          this.openQuery = value;
          this.loadMore();
      },
      loadMore() {
          if (this.loading) {
              return
          }
          this.getOpenBankList();
      },
      infoSubmit() {
        this.$refs["addForm"].validate((valid) => {
          if(valid) {
            let dto = {
                bankName: this.addForm.bankName,
                payBankNo: this.addForm.payBankNo,
            }
            this.openBankList.forEach(item =>{
                if(item.label == this.addForm.openBankName) {
                    dto.bankCode = item.bankCode
                }
            })
            this.post('/mall-service/merchant/v1/save/bank/info',dto).then(res => {
                this.$message({
                    message: '提交成功！',
                    type: 'success',
                })
                this.$refs.myList.get();
                this.formClose()
            }).catch(err => {})
          }
        })
      },
      formClose(){
          this.$nextTick(() => {
              this.$refs['addForm'].resetFields()
              this.openQuery = ''
              this.openBankList = []
          })
          this.dialogFormVisible = false
      }
    }
  };
</script>
<style lang="scss" scope>
  .el-dialog {
    margin-top: 28vh !important;
    width: 703px;
  }

  .el-dialog__footer {
    text-align: center;
  }

  .dialog_bottom {
    margin-top: 23px;
    width: 100%;
    text-align: center;

    .el-button {
      width: 80px !important;
      height: 36px !important;
      padding-top: 10px;
    }
  }
  .info-tips{
      padding: 2px;
      color: #999;
  }
</style>